import img1 from "./assets/doorvi/product/DoorVi - Doorbell for home.jpg"
import img2 from "./assets/doorvi/product/DoorVi - Doorbell.jpg"
import img3 from "./assets/doorvi/product/DoorVi - QR code doorbell.jpg"
import img4 from "./assets/doorvi/product/DoorVi - Smart Doorbell.jpg"
import img5 from "./assets/doorvi/product/DoorVi - Wireless Doorbell.jpg"
import img6 from "./assets/doorvi/product/DoorVi - doorbell for office.jpg"
import img7 from "./assets/doorvi/product/DoorVi - Doorbell.jpg"
import bellHome1 from "./assets/doorvi/homeBell/1.png"
import bellHome2 from "./assets/doorvi/homeBell/2.png"
import bellHome3 from "./assets/doorvi/homeBell/3.png"
import bellHome4 from "./assets/doorvi/homeBell/4.png"
import bellHome5 from "./assets/doorvi/homeBell/5.png"

import squrareBell1 from "./assets/doorvi/product/blackasc/1.jpg"
// import squrareBell2 from "./assets/doorvi/product/blackasc/3.png"
import squrareBell3 from "./assets/doorvi/product/blackasc/2.png"
import squrareBell4 from "./assets/doorvi/product/blackasc/3.png"
import squrareBell5 from "./assets/doorvi/product/blackasc/4.png"
import squrareBell6 from "./assets/doorvi/product/blackasc/5.png"

import Hotel1 from "./assets/doorvi/product/hotel/1.png"
import Hotel2 from "./assets/doorvi/product/hotel/2.png"
import Hotel3 from "./assets/doorvi/product/hotel/3.png"
import Hotel4 from "./assets/doorvi/product/hotel/4.png"
import Hotel5 from "./assets/doorvi/product/hotel/5.png"
// import Hotel6 from "./assets/doorvi/product/hotel/6.png"

import v1 from "./assets/doorvi/product/v1.png";
import v2 from "./assets/doorvi/product/v2.png";
import v3 from "./assets/doorvi/product/v3.png";
import v4 from "./assets/doorvi/product/v4.png";
import v5 from "./assets/doorvi/product/v5.png";
import v6 from "./assets/doorvi/product/v6.png";
import l1 from "./assets/doorvi/product/lots/l01.jpg";
import l2 from "./assets/doorvi/product/lots/l02.jpg";
import l3 from "./assets/doorvi/product/lots/l03.jpg";
import l4 from "./assets/doorvi/product/lots/l04.jpg";
import l5 from "./assets/doorvi/product/lots/l05.jpg";
import l6 from "./assets/doorvi/product/lots/l06.jpg";
import l7 from "./assets/doorvi/product/lots/l07.jpg";
import l8 from "./assets/doorvi/product/lots/l08.jpg";
import l9 from "./assets/doorvi/product/lots/l09.jpg";


const data = {
  "productData": [
    {
      "cat_name": "Qr Codes",
      "id": 1,
      "items": [
        {
          "cat_name": "QR",
          "products": [
            {
              "productName": "DoorVi Smart Video Doorbell Powered by QR Code Technology | Instant Visitor Video Call on Smartphone | QR Scannable Technology | 2-Way Talk (Square)",
              "catImg": img7,
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/homeSquare.jpg",
              "size": [],
              "brand": "DoorVi",
              "productImages": [
                img2,
                img1,
                img3,
                img4,
                img5,
                img6,
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforHomeandOffice_Square",
                product_dimensions:"10.5 x 7 x 0.1 cm, 10g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"1.00 Piece",
                width:"15cm",
                components:"User Manual, QR Sticker, Envelope",
                genericName:"Long lasting pvc vinyl sticker which is highly durable, waterproof and scratch-resistant.",  

              },              
              details: ["Peel and Paste the DoorVi unit anywhere, with no internet, electricity, or wiring required.", "Respond to visitors from anywhere, using your smartphone and the free DoorVi app.", "Connect with your visitors over a video call.You can see the visitor's video but the visitor won't be able to see you.", "You can provide instructions for Delivery from anywhere.", "Add other members to your DoorVi so they can answer the door too", "To contact you, visitors can scan the QR Code using a smartphone - they don't need an app to use DoorVi.", "Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful to your visitors.", "DoorVi unit is manufactured with a long- lasting material which is highly durable, waterproof and scratch - resistant.", "Replace Doorbells with DoorVi Unit Powered by QR Code Scannable Technology"],
              "rating": 5,
              "ratingPeople": 5300,
              "description": "The DoorVi Video Calls bring convenience and security to your most valuable asset. Your home. Any Visitor standing outside the house needs to scan the QR code and connect with the house owner over a video call. The house owner can see and speak with visitors from anywhere in the world on a smartphone. Once the DoorVi kit arrives, download the DoorVi app from Google / Apple App Store to activate your QR code and pair it to your DoorVi Address. Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful and visible to your visitors. DoorVi unit is manufactured with a long-lasting material that is highly durable, waterproof, and scratch-resistant.",
              "id": "Qr-Code-DoorBell-Powered-by-Doorvi"
            },
            {
              "productName": "DoorVi Smart Video Doorbell Powered by QR Code Technology | Instant Visitor Video Call on Smartphone | QR Scannable Technology | 2-Way Talk (BellDesign)",
              "catImg": bellHome1,
              "brand": "DoorVi",
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/homeBell.png",
              "productImages": [
                bellHome1,
                bellHome2,
                bellHome3,
                bellHome4,
                bellHome5,                
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforHomeandOffice_BellDesign",
                product_dimensions:"11 x 10 x 0.1 cm, 10g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"1.00 Piece",
                width:"15cm",
                components:"User Manual, QR Sticker, Envelope",
                genericName:"Long lasting pvc vinyl sticker which is highly durable, waterproof and scratch-resistant.",  

              },
              details: ["Peel and Paste the DoorVi unit anywhere, with no internet, electricity, or wiring required.", "Respond to visitors from anywhere, using your smartphone and the free DoorVi app.", "Connect with your visitors over a video call.You can see the visitor's video but the visitor won't be able to see you.", "You can provide instructions for Delivery from anywhere.", "Add other members to your DoorVi so they can answer the door too", "To contact you, visitors can scan the QR Code using a smartphone - they don't need an app to use DoorVi.", "Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful to your visitors.", "DoorVi unit is manufactured with a long- lasting material which is highly durable, waterproof and scratch - resistant.", "Replace Doorbells with DoorVi Unit Powered by QR Code Scannable Technology"],
              "rating": 4.5,
              "ratingPeople": 8300,
              "description": "The DoorVi Video Calls bring convenience and security to your most valuable asset. Your home. Any Visitor standing outside the house needs to scan the QR code and connect with the house owner over a video call. The house owner can see and speak with visitors from anywhere in the world on a smartphone. Once the DoorVi kit arrives, download the DoorVi app from Google / Apple App Store to activate your QR code and pair it to your DoorVi Address. Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful and visible to your visitors. DoorVi unit is manufactured with a long-lasting material that is highly durable, waterproof, and scratch-resistant.",
              "id": "Qr-Code-DoorBell-Powered-by-Doorvi-Bell"
            },
            {
              "productName": "DoorVi Smart Video Doorbell Powered by QR Code Technology | Instant Visitor Video Call on Smartphone | QR Scannable Technology | 2-Way Talk (AcrylicDesign)",
              "catImg": squrareBell1,
              "brand": "DoorVi",
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/homeBell.png",
              "productImages": [
                squrareBell1,
                // squrareBell2,
                squrareBell3,
                squrareBell4,
                squrareBell5,
                squrareBell6,
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforHomeandOffice_AcrylicDesign",
                product_dimensions:"11.5 x 6 x 0.2 cm, 10g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"1.00 Piece",
                width:"15cm",
                components:"User Manual,Acrylic QR Sticker, Envelope",
                genericName:"Long lasting Acrylic sticker which is highly durable, waterproof and scratch-resistant.",  

              },
              details: ["Peel and Paste the DoorVi unit anywhere, with no internet, electricity, or wiring required.", "Respond to visitors from anywhere, using your smartphone and the free DoorVi app.", "Connect with your visitors over a video call.You can see the visitor's video but the visitor won't be able to see you.", "You can provide instructions for Delivery from anywhere.", "Add other members to your DoorVi so they can answer the door too", "To contact you, visitors can scan the QR Code using a smartphone - they don't need an app to use DoorVi.", "Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful to your visitors.", "DoorVi unit is manufactured with a long- lasting material which is highly durable, waterproof and scratch - resistant.", "Replace Doorbells with DoorVi Unit Powered by QR Code Scannable Technology"],
              "rating": 4.5,
              "ratingPeople": 900,
              "description": "The DoorVi Video Calls bring convenience and security to your most valuable asset. Your home. Any Visitor standing outside the house needs to scan the QR code and connect with the house owner over a video call. The house owner can see and speak with visitors from anywhere in the world on a smartphone. Once the DoorVi kit arrives, download the DoorVi app from Google / Apple App Store to activate your QR code and pair it to your DoorVi Address. Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful and visible to your visitors. DoorVi unit is manufactured with a long-lasting material that is highly durable, waterproof, and scratch-resistant.",
              "id": "Qr-Code-DoorBell-Powered-by-Doorvi-Acrylic-Design"
            },
            {
              "productName": "DoorVi Smart Security for Cars and Vehicles for Emergency or Wrong Parking Alerts | Powered by QR Code Technology | Instant Video Call on Smartphone | 2-Way Talk",
              "catImg": v1,
              "brand": "DoorVi",
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/vehicle.png",
              "productImages": [
                v1,
                v2,
                v3,
                v4,
                v5,
                v6
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforVehicle",
                product_dimensions:"8.5 x 7.5 x 0.1 cm, 10g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"1.00 Piece",
                width:"15cm",
                components:"User Manual, QR Sticker, Envelope",
                genericName:"Long lasting pvc vinyl sticker which is highly durable, waterproof and scratch-resistant.",  

              },
              details: [
                "Peel & Paste the DoorVi Unit to the Car Window from Inside",
                "Receive Calls From Anywhere On Your Smartphone Without Revealing Your Identity.",
                "You Will Know The Person Calling You Without Picking The Video Call",
                "High-Level Of Security By Allowing People or Traffic Police To Connect With You Over a Video Call",
                "Whenever Any Individual Scans The QR Code On Your Vehicle, You As Well As Other Members (Added) Will Receive A Call Notification Alert On The Mobile Phone.",
                "To contact you, user can scan the QR Code using a smartphone - they don't need an app to use DoorVi",
                "Remove the backing from your DoorVi unit, and stick it to the Car Window from Inside, where it is most visible",
                "DoorVi unit is manufactured with a long-lasting material which is highly durable, waterproof and scratch-resistant",
                "There Is No Need For Maintenance, Electricity or Repairing, Allowing You To Get The Most Out Of Your Small Investment"
              ],
              "rating": 4.5,
              "ratingPeople": 10300,
              "description": "The DoorVi Video Calls bring convenience and security to your most valuable asset. Your Vehicle. DoorVi QR Code on your vehicle helps people to connect with you in case of wrong parking, accident, or roadside assistance. The user parks their vehicle at any spot which might be a reason or issue for someone. With the help of the DoorVi QR Code, the person can quickly contact you to take necessary action. This communication process allows you to make decisions on time, maintain privacy, and save your vehicle from getting towed or damaged, thus, saving money and time. The Vehicle owner can see and speak with the user from anywhere in the world on a smartphone. Once the DoorVi kit arrives, Download the DoorVi App from Google / Apple App Store to activate your QR code and pair it to your DoorVi Vehicle. Carefully Peel Off The Sticky Layer of the Qr Code and Paste the QR Sticker on the Car Window From the Inside. Start From The Center Of The Sticker And Push It Towards The Edges So It Sticks Properly. DoorVi unit is manufactured with a long-lasting, durable, waterproof, and scratch-resistant material.",
              "id": "doorvi-qr-code-technology-help-you-to-connected-with-your-vehicle"
            },
            {
              "productName": "DoorVi QR Code Powered Lost & Found Labels for Luggage, Personal Items, Toys & More | Instant Smartphone Video Call | 2-Way Talk",
              "catImg": l1,
              "brand": "DoorVi",
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/lostsandfound.jpg",
              "productImages": [
                l1,
                l2,
                l3,
                l4,
                l5,
                l6,
                l7,
                l8,
                l9
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforLostandFound",
                product_dimensions:"4 x 4 x 0.1 cm, 5g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"5.00 Pieces",
                width:"15cm",
                components:"User Manual, QR Sticker, Envelope",
                genericName:"Long lasting pvc vinyl sticker which is highly durable, waterproof and scratch-resistant.",  

              },
              details: [
                "HIGH QUALITY — 5 Durable Square Waterproof Stickers. Made In India",
                "CUSTOM AND UNIQUE — Each Sticker Has A Unique QR Code And Can Be Attached To A Different Item Like Your Phone, Tablet, Laptop, Earbuds, Camera, Passport And Credit Cards",
                "EASY TO SETUP — Activate Each Label In The DoorVi App. Peel & Paste The QR Sticker On Your Personal Items. Create Multiple Categories From App Settings And Activate Each Sticker.",
                "FIND IT — If Someone Finds Your Lost Stuff, They Can Scan The Label And Can Talk To You Over A Video Call.",
                "SUPER DURABLE — DoorVi Lost And Found Labels Are Manufactured With A Long-Lasting Material Which Is Highly Durable, Waterproof And Scratch-Resistant. Simply Place On Any Item With A Smooth Dry Surface.",
                "QR TECHNOLOGY — With Android And iOS-Compatible QR Code Technology, The DoorVi Labels Stay Scannable — Without The Need For A Battery.",
                "EASY TO UPDATE — You Can Update Your Contact Information, Add Multiple Members From The Free DoorVi App Available In Both Google And Apple App Store.",
                "EASY TO USE — View And Update All Your Tagged Item From The Home screen Of The DoorVi App.",
              ],
              "rating": 4.5,
              "ratingPeople": 3300,
              "description": "DoorVi - Lost and Found is a QR sticker that can be attached to your valuable items like Tablets, Lunchboxes, Water Bottles, Laptop, Earbuds, Camera, Passport, Credit Cards, and More, so that if you lose any of them, whoever finds it can scan it with a smartphone camera in order to contact you and return it back to you. Once the DoorVi kit arrives, Download the DoorVi App from Google / Apple App Store to activate your QR code and pair it to your Personal Items.",
              "id": "DoorVi-QR-Code-Powered-Lost-&-Found-Labels-for-Luggage-Personal-Items-Toys-&-More"
            },         
            {
              "productName": "DoorVi Smart Video Doorbell Powered by QR Code Technology | Instant Guest Video Call on Smartphone | QR Scannable Technology | 2-Way Talk (For Hotels)",
              "catImg":Hotel1,
              "brand": "DoorVi",
              "stripeImg":"https://doorvi-call-log-images.fra1.cdn.digitaloceanspaces.com/website-images/hotel.png",
              "productImages": [
                Hotel1,
                Hotel2,
                Hotel3,
                Hotel4,
                Hotel5,                
              ],
              "additionalInfo":{
                manufacturer:"Codeplay Labs Private Limited, Mr Jatin Babbar , 9711150832 , Address - Tower A, Office No 602, The I Thum Tower, A-40, Sector-62, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201309",
                country:"India",
                model_num:"DoorViforHotels",
                product_dimensions:"11 x 10 x 0.1 cm, 10g",
                item_dimensions:"8.5 x 15 x 1cm",
                weight:"50g",
                quantity:"1.00 Piece",
                width:"15cm",
                components:"User Manual, QR Sticker, Envelope",
                genericName:"Long lasting pvc vinyl sticker which is highly durable, waterproof and scratch-resistant.",  

              },
              details: ["DoorVi is a Smart Video Doorbell Powered by QR Code Technology that instantly Connects Hotel Staff over an audio- video call or Chat.",
                "Install DoorVi anywhere in guest rooms, without Wires/ Wifi.",
              "Guest can connect with a smartphone to chat directly with desk.",
              "Privacy Preserving – No Personal information for Guest / Staff is required.",
              "No Maintenance, Installation and Hardware Involved.",
              "The hotel Owner/ Staff can see and speak with guests from anywhere on a smartphone. Once the DoorVi kit arrives, download the DoorVi app from Google / Apple App Store to activate your QR code and pair it to your DoorVi Address. Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful and visible to your visitors. DoorVi unit is manufactured with a long-lasting material that is highly durable, waterproof, and scratch-resistant."
            ],
              "rating": 4.7,
              "ratingPeople": 690,
              "description": `DoorVi is a Smart Video Doorbell Powered by QR Code Technology that instantly Connects Hotel Staff over an audio- video call or Chat.\n
               Install DoorVi anywhere in guest rooms, without Wires/ Wifi.\n
               Guest can connect with a smartphone to chat directly with desk.\n
               Privacy Preserving – No Personal information for Guest / Staff is required.\n
               No Maintenance, Installation and Hardware Involved.\n
              The hotel Owner/ Staff can see and speak with guests from anywhere on a smartphone. Once the DoorVi kit arrives, download the DoorVi app from Google / Apple App Store to activate your QR code and pair it to your DoorVi Address. Remove the backing from your DoorVi unit, and stick it to any door, desk, or gate where it is most useful and visible to your visitors. \nDoorVi unit is manufactured with a long-lasting material that is highly durable, waterproof, and scratch-resistant.`,
              "id": "doorvi-qr-code-enhances-communication-&-security-in-hotel"
            },
          ]
        },
      ]
    },
  ],
}

export const productTagLine = [
  "Our Bestseller",
  "Top Pick",
  "Customer Favorite",
  "Preferred Choice",
  "Luxury Defined",
  "Experience Excellence"
];
export default data;