import axios from "axios";

const uris = {
  dev: "https://dev.api.doorvi.co/api",
  production: "https://api.doorvi.co/api",
  psLink: "https://app.doorvi.co/join",
  privacyLink: "https://www.doorvi.co/PrivacyPolicy.html",
};


export const axiosBaseUrl = {
  url: axios.create({
    baseURL: uris.production,
  }),
};
export default axiosBaseUrl.url;

export { uris };
