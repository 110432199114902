import baseUrl from "./baseUrl";
export const getAllPlans = async (
  
) => {
  try {
    const res = await baseUrl.get("/getAllPlans");
    // console.log("prenotify", res.data);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};